import { Types } from "../Common";
console.log("Types", Types);
/////////////////////////////////////
/////////  INTERNAL ACTION TRIGGERS  /////////
/////////////////////////////////////
export const beginWalkthrough = () => {
  return {
    type: Types.COMMON.BEGIN_WALKTHROUGH
  };
};

export const finishWalkthrough = () => {
  return {
    type: Types.COMMON.FINISH_WALKTHROUGH
  };
};
export const userLoggedIn1 = () => ({
    type: Types.COMMON.USER_LOGGED_IN
});
export function userLoggedIn() {
  return {
    type: Types.COMMON.USER_LOGGED_IN
  }
}
export const logoutUser = () => {
  return {
    type: Types.USER.LOGOUT
  };
};
export const getDirectus = () => {
  return {
    type: Types.COMMON.GET_DIRECTUS
  };
};
/////////////////////////////////////
/////////  USER ACTION TRIGGERS  /////////
/////////////////////////////////////
export const getUserInfo = () => ({
  type: Types.USER.GET_INFO,
  request: {
    method: "GET",
    url: `/users/me`
  }
});

export const loginUser = (email, password) => ({
  type: Types.USER.LOGIN,
  request: {
    method: "POST",
    url: `/auth/authenticate`,
    data: {
      hej: "hej2",
      ...(email && { email }),
      ...(password && { password })
    },
    meta: {
      normalize:true,
      asPromise:true,
      getData: data => ({
        ...data[0]
      }),
      mutations: {
        [Types.USER.LOGIN]: {
          updateData: data => data,
          getData: data => ({
            ...data[0]
          }),
          [Types.USER.LOGIN]: (data, mutationData) => {data,mutationData}
        },
        [Types.USER.LOGIN]: (data, mutationData) => {data,mutationData},
        getData: data => ({
          ...data[0]
        }),
      },
    },
  }
});
export const createUser = (email, password, first_name, last_name) => ({
  type: Types.USER.CREATE,
  request: {
    method: "POST",
    url: `/users`,
    normalize:true,
    asPromise:true,
    data: {
      ...(email && { email }),
      ...(password && { password }),
      ...(first_name && { first_name }),
      ...(last_name && { last_name }),
      role: 3,
      status: "active"
    }
  }
});
export const updateUser = (id, email, password, first_name, last_name) => ({
  type: Types.USER.UPDATE_INFO,
  request: {
    method: "PATCH",
    url: `/users/${id}`,
    data: {
      ...(email && { email }),
      ...(password && { password }),
      ...(first_name && { first_name }),
      ...(last_name && { last_name })
    }
  }
});
export const forgotPassword = email => ({
  type: Types.USER.FORGOT_PASSWORD,
  request: {
    method: "POST",
    url: `/auth/password/request`,
    data: {
      ...(email && { email })
    }
  }
});

export const refreshToken = (newToken) => ({
    type: Types.USER.REFRESH_TOKEN,
    payload:newToken
});

/////////////////////////////////////
/////////  OFFER ACTION TRIGGERS  /////////
/////////////////////////////////////
export const fetchAllOffers = () => ({
  type: Types.OFFER.FETCH_ALL,
  request: {
    method: "GET",
    normalize:false,
    asPromise:true,
    url: `/items/offers?fields=*.*&filter[status]=published`,
    meta: {
      normalize:false,
      asPromise:true,
    },
  }
});
export const fetchAcceptedOffers = email => ({
  type: Types.OFFER.FETCH_ACCEPTED,
  request: {
    method: "POST",
    url: `/items/accepted_offers?fields=*.*`,
    data: {
    }
  }
});
export const fetchOffersWithFilter = email => ({
  type: Types.OFFER.FETCH_FILTERED,
  request: {
    method: "POST",
    url: `/items/offers?fields=*.*q`,
    data: {
    }
  }
});

/////////////////////////////////////
/////////  LOCATION ACTION TRIGGERS  /////////
/////////////////////////////////////
export const fetchAllLocations = () => ({
  type: Types.LOCATION.FETCH_ALL,
  request: {
    method: "GET",
    normalize:true,
    asPromise:true,
    url: `/items/locations?fields=*.*&filter[status]=published`,
    meta: {
      normalize:true,
      asPromise:true,
    },
  }
});

export const fetchAllLocationsRatings = () => ({
  type: Types.LOCATION.FETCH_RATINGS,
  request: {
    method: "GET",
    normalize:true,
    asPromise:true,
    url: `/items/location_rating?fields=*.*&filter[status]=published`,
    meta: {
      normalize:true,
      asPromise:true,
    },
  }
});

export const fetchAllLocationsFavorites = () => ({
  type: Types.LOCATION.FETCH_FAVEORITES,
  request: {
    method: "GET",
    normalize:true,
    asPromise:true,
    url: `/items/location_favorite?fields=*.*&filter[status]=published`,
    meta: {
      normalize:true,
      asPromise:true,
    },
  }
});

export const fetchAdminLocations = (id) => ({
  type: Types.LOCATION.FETCH_LOCATION_ADMIN,
  request: {
    method: "GET",
    normalize:true,
    asPromise:true,
    url: `/items/location_admin?fields=location.*&filter[status]=published&filter[user.id]=${id}`,
    meta: {
      normalize:true,
      asPromise:true,
    },
  }
});

/////////////////////////////////////
/////////  FRIENDS ACTION TRIGGERS  /////////
/////////////////////////////////////
export const fetchAllFriends = () => ({
  type: Types.FRIENDS.FETCH_ALL,
  request: {
    method: "GET",
    normalize:true,
    asPromise:true,
    url: `/items/friends?fields=*.*&filter[status]=published`,
    meta: {
      normalize:true,
      asPromise:true,
    },
  }
});


export const fetchMyFriends = () => ({
  type: Types.FETCH_MY_FRIENDS,
  request: {
    method: "GET",
    normalize:true,
    asPromise:true,
    url: `/items/friends?fields=*.*&filter[status]=published`,
    meta: {
      normalize:true,
      asPromise:true,
    },
  }
});