import React, { Component } from "react";
import {
  View,
  TouchableOpacity,
  TouchableHighlight,
  SafeAreaView,
  StyleSheet
} from "react-native";
import * as actions from "../../actions";
import { connect } from "react-redux";
import TakeerText from "../../components/TakeerText";
import TakeerIcon from "../../components/TakeerIcon";
import { Styles, Colors } from "../../Common";
import { getDirectus } from "../../Store";

import {
  Container,
  Header,
  Title,
  Subtitle,
  Content,
  ActionSheet,
  Footer,
  FooterTab,
  Button,
  Left,
  Right,
  Body,
  Icon,
  Text,
  Fab,
  Tabs,
  Tab,
  TabHeading,
  Picker, Form
} from "native-base";

const AppleCard = ({ item, index }) => (
  <View key={`${index}-key`}>
    <View
      style={{
        width: "100%",
        padding: 16,
        backgroundColor: "#e6c8b6"
      }}
    >
      <TakeerText
        style={{
          fontSize: 12,
          marginBottom: 5,
          fontWeight: "bold",
          color: "rgba(0, 0, 0, 0.5)"
        }}
      >
        {`Created at ${item["created_on"]}
Publish date ${item["publish_date"]}`}
        
      </TakeerText>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <TakeerText
          style={{
            fontSize: 26,
            maxWidth: "60%",
            fontWeight: "bold",
            color: "rgb(51, 51, 51)"
          }}
        >
          {item["offer_description"]}
        </TakeerText>
        <View>
          <TouchableOpacity
            style={{
              backgroundColor: "#44403d",
              padding: 7,
              minWidth: 60,
              borderRadius: 16,
              right: 0,
              marginBottom: 10
            }}
            onPress={() => {
              console.log("IG Card");
            }}
          >
            <TakeerText
              style={{ color: "rgb(255, 255, 255)", textAlign: "center" }}
            >
              <TakeerIcon
                iconType="FontAwesome"
                iconName="edit"
                iconSize={20}
                iconColor="#ef7e20"
                iconPosition="center"
              />{" "}
            </TakeerText>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              backgroundColor: "#44403d",
              padding: 7,
              minWidth: 60,
              borderRadius: 16,
              right: 0
            }}
            onPress={() => {
              console.log("IG Card");
            }}
          >
            <TakeerText
              style={{ color: "rgb(255, 255, 255)", textAlign: "center" }}
            >
              <TakeerIcon
                iconType="FontAwesome"
                iconName="trash"
                iconSize={20}
                iconColor="#ef7e20"
                iconPosition="center"
              />{" "}
            </TakeerText>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  </View>
);

const cardsComponent = (cards) => {

    return (<Card style={{flex: 0}}>
        <CardItem>
          <Left>
            <Thumbnail source={{uri: 'Image URL'}} />
            <Body>
              <Text>NativeBase</Text>
              <Text note>April 15, 2016</Text>
            </Body>
          </Left>
        </CardItem>
        <CardItem>
          <Body>
            <Image source={{uri: 'Image URL'}} style={{height: 200, width: 200, flex: 1}}/>
            <Text>
              //Your text here
            </Text>
          </Body>
        </CardItem>
        <CardItem>
          <Left>
            <Button transparent textStyle={{color: '#87838B'}}>
              <Icon name="logo-github" />
              <Text>1,926 stars</Text>
            </Button>
          </Left>
        </CardItem>
      </Card>)
}

class LocationAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clicked: null,
      selectedLocation: null,
    };
  }
  groupBy = (list, keyGetter) => {
    const map = new Map();
    list.forEach(item => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  };
  search = (item) => {
    return Object.keys(this).every((key) => item[key] === this[key]);
  }
  async componentDidMount() {
    const localDirectus = await getDirectus();
    // get locationAdmin
    // get offers for each location
    const locationsAdminData = await localDirectus
      .getItems("location_admin", {
        filter: {
          "user.id": this.props.userID,
          status: "published"
        },
        fields: "*,location.*"
      })
      .catch(err => console.log(err));
    console.log("LocationAdmin", locationsAdminData);
    this.setState({ selectedLocation: `locationID-${locationsAdminData.data[0].id}`});
    this.setState({ locations: locationsAdminData.data })
    const locationIds = locationsAdminData.data
      .map(location => location.id)
      .toString();
    console.log("LocationIDS", locationIds);
    
    const offersData = await localDirectus
      .getItems("offers", {
        filter: {
          "location.id": {
            in: locationIds
          }
        },
        fields: "*.*"
      })
      .catch(err => console.log(err));
    console.log("offers", offersData);
    //const groupedLocations = this.groupBy(LocationsAdminData.data, location => location.id);
    const groupedOffers = this.groupBy(offersData.data, offer => offer.location.id);
    console.log("Grouped offers", groupedOffers);
    this.setState({ offers: groupedOffers });
    //this.setState({ locations: groupedLocations });
  }
  onPickerValueChange(value) {
    this.setState({
        selectedLocation: value
    });
  }
  render() {
    const { locations, offers, selectedLocation } = this.state;
    console.log("Redering", locations, offers, selectedLocation);
    
    let i = 1;
    if (locations && offers) {

        console.log("Offers for: ",selectedLocation,offers.get(parseInt(selectedLocation.split("-")[1])))
      const multipleLocations = locations.length > 1;
      const locationOffers = {
        "Active": offers.get(parseInt(selectedLocation.split("-")[1])).filter(x => x.status == 'published' && x.distance == '2000'),
        "Draft": offers.get(parseInt(selectedLocation.split("-")[1])).filter(x => x.status == 'draft' && x.distance == '2000'),
        "Pending": offers.get(parseInt(selectedLocation.split("-")[1])).filter(x => x.status == 'published' && x.distance == '2000'),
        "Old": offers.get(parseInt(selectedLocation.split("-")[1])).filter(x => x.status == 'published' && x.distance == '2000'),
      };
      console.log("ActiveLocationOffers",locationOffers)
      const RightHeader =
        locations.length > 1 ? (
          <Right>
            <Picker
              mode="dropdown"
              iosHeader="Select your location"  
              placeholder=""
              iosIcon={<Icon name="ios-swap" />}
              style={{ width: undefined }}
              selectedValue={selectedLocation}
              textStyle={{color:"transparent", width:10}}
              onValueChange={this.onPickerValueChange.bind(this)}
            >
            {locations.map(location => (<Picker.Item label={location.location.name} value={`locationID-${location.location.id}`} />))}

            </Picker>
          </Right>
        ) : (
          <Right />
        );
        const SubTitleValue = locations.find(obj => obj.location.id == selectedLocation.split("-")[1]).location.name;//locations.find( ({ location }) => location.id === selectedLocation);
        console.log("SubTitle",SubTitleValue)
      return (
        <Container>
          <Header transparent hasTabs hasSubtitle>
            <Left>
              <Button transparent onPress={() => this.props.navigation.goBack()}>
                <Icon name="arrow-back" />
                <Text>Close</Text>
              </Button>
            </Left>
            <Body hasText hasSubtitle>
              <Title>Admin</Title>
              <Subtitle style={{width:300}}>{SubTitleValue}</Subtitle>
            </Body>
            {RightHeader}
          </Header>
          <Tabs>
            <Tab
              heading={
                <TabHeading>
                  <TakeerIcon
                    iconType="MaterialIcons"
                    iconName="notifications-active"
                    iconSize={25}
                  />
                  <Text>Active</Text>
                </TabHeading>
              }
            >
              {locationOffers.Active && 
                locationOffers.Active.map(offer => (
                  <AppleCard index={offer.id} item={offer}/>
              ))}
            </Tab>
            <Tab
              heading={
                <TabHeading>
                  <TakeerIcon
                    iconType="MaterialIcons"
                    iconName="notifications-none"
                    iconSize={25}
                  />
                  <Text>Drafts</Text>
                </TabHeading>
              }
            >
                {locationOffers.Draft && 
                    locationOffers.Draft.map(offer => (
                  <AppleCard index={offer.id} item={offer}/>
              ))}
            </Tab>
            <Tab
              heading={
                <TabHeading>
                  <TakeerIcon
                    iconType="MaterialIcons"
                    iconName="notifications-paused"
                    iconSize={25}
                  />
                  <Text>Pending</Text>
                </TabHeading>
              }
            >
              {locationOffers.Pending && 
                locationOffers.Pending.map(offer => (
                  <AppleCard index={offer.id} item={offer}/>
              ))}
            </Tab>
            <Tab
              heading={
                <TabHeading>
                  <TakeerIcon
                    iconType="MaterialIcons"
                    iconName="notifications-off"
                    iconSize={25}
                  />
                  <Text>Old</Text>
                </TabHeading>
              }
            >
              {locationOffers.Old && 
              locationOffers.Old.map(offer => (
                  <AppleCard index={offer.id} item={offer}/>
              ))}
            </Tab>
          </Tabs>
        </Container>
      );
    } else {
      return (
        <SafeAreaView>
          <View>
            <TakeerText>Waiting for data</TakeerText>
          </View>
        </SafeAreaView>
      );
    }
  }
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F5F5F5"
  },

  activityIndicatorContainer: {
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
    flex: 1
  },

  floatingButton: {
    backgroundColor: Colors.primaryLight,
    borderColor: "#6B9EFA",
    height: 55,
    width: 55,
    borderRadius: 55 / 2,
    alignItems: "center",
    justifyContent: "center",

    shadowColor: "#000000",
    shadowOpacity: 0.5,
    shadowRadius: 2,
    shadowOffset: {
      height: 1,
      width: 0
    }
  }
});
const mapStateToProps = state => ({
  settings: state.settings,
  requests: state.requests,
  userID: state.COMMON.userLoginInfo.response.data.data.user.id
});
export default connect(mapStateToProps, actions)(LocationAdmin);
