import React, {useState} from 'react';
import { 
    View,
    StatusBar,
    Image
} from 'react-native';

import { createAppContainer } from 'react-navigation';
import { createStackNavigator } from 'react-navigation-stack';
import { createBottomTabNavigator } from 'react-navigation-tabs';
import { createDrawerNavigator } from 'react-navigation-drawer';

import { AppLoading } from 'expo';
import { AppLoader } from './AppLoader'
import { useFonts } from '@use-expo/font';
import * as Font from 'expo-font';
import * as ScreenOrientation from 'expo-screen-orientation';
////////////////////////////////////////////////
//////////////////// REDUX IMPORT //////////////
import { Provider, connect } from 'react-redux';
import configureStore from './Store';
import { Colors, Images } from './Common';

//FONTS

import { Ionicons as I } from '@expo/vector-icons';
import { Entypo as En } from '@expo/vector-icons';
import { EvilIcons as Ev } from '@expo/vector-icons';
import { FontAwesome as F } from '@expo/vector-icons';
import { MaterialCommunityIcons as Mc } from '@expo/vector-icons';
import { MaterialIcons as M } from '@expo/vector-icons';
import { SimpleLineIcons as S } from '@expo/vector-icons';
import { Zocial as Z } from '@expo/vector-icons';
import { Octicons as O } from '@expo/vector-icons';
import { Feather as Fe } from '@expo/vector-icons';
import { Foundation as Fa } from '@expo/vector-icons';

//////////////////////////////////////////////////
//////////////////// IMPORT PAGES ////////////////
//Main Tabs
import TabHome from './pages/TabHome';

//Import Pages
import Drawer from './Drawer';
import Walkthrough from './pages/Walkthrough';
import LoginSignup from './pages/LoginSignup';
import Modal from './pages/Modal';
import Location from './pages/Location';

import LocationDetail from './pages/LocationDetail';
import LocationAdmin from './pages/LocationAdmin';
import Chat from './pages/Chat';
import Congrats from './pages/Congrats';
import Instructor from './pages/User/Instructor';
import Juicer from './pages/User/Juicer';

//store = configureStore.configureStore(() => {console.log("STORE CONFIGURED")});
store = configureStore(() => {});

//////////////////////////////////////////////////
/////////////////// NAVIGATORS ///////////////////
//Tab Navigator for Home and Settings Screen
const TTabNavigator = createBottomTabNavigator({
    TabHome:{
        screen: TabHome,
        navigationOptions:{
            header:"Home",
            tabBarIcon: ({tintColor}) => <Image 
            source={Images.icons.logo} 
            style={{
                shadowColor: "#000",
                shadowOffset: {
                width: 0,
                height: 0,
            },
            shadowOpacity: 0.69,
            shadowRadius: 1,}}/>,
        },
        defaultNavigationOptions: ({ navigation }) => ({
            tabBarOnPress: ({ navigation, defaultHandler }) => {
              console.log('onPress:', navigation.state.routeName);
              defaultHandler()
            },
          }),
    },
 
},{
    tabBarPosition: 'bottom',
    animationEnabled: true,
    swipeEnabled: false,
    defaultNavigationOptions: ({ navigation }) => ({
        tabBarOnPress: ({ navigation, defaultHandler }) => {
          console.log('onPress:', navigation.state.routeName);
          if(navigation.state.routeName == "TabHome") navigation.toggleDrawer();
          defaultHandler()
        },
      }),
    tabBarOptions: {
      activeTintColor: Colors.primaryAccent,
      inactiveTintColor: Colors.textSecondary,
      showLabel:false,
      showIcon:true,
      indicatorStyle:{
        backgroundColor: 'transparent',
      },
      tabStyle: {
        justifyContent:'center',
        alignItems:'flex-end',
        /*...Platform.select({
            ios:{
                paddingBottom:20,
            },
            android:{
                paddingBottom:10,
            }
        }),*/
        paddingHorizontal:0,
        backgroundColor: 'transparent',//Colors.secondary,
        border: 0,
        borderTop:0,
        borderTopColor: "transparent"
      },
      style: {
        backgroundColor: 'transparent',//Colors.secondary,
        position: 'absolute',
        left: 0,
        right: '10%',
        bottom: '4%',
        height: 6,
        border:0,
        borderTop:0,
        borderTopColor: "transparent"
      },
    },
});


//wrap tab navigation into drawer navigation
const TabsWithDrawerNavigation = createDrawerNavigator({
    Tbs: {
        screen: TTabNavigator,
    }
},{
    drawerOpenRoute: 'DrawerOpen',
    drawerCloseRoute: 'DrawerClose',
    drawerToggleRoute: 'DrawerToggle',
    drawerWidth: 300,
    contentComponent: props => <Drawer {...props} />,
    drawerBackgroundColor: 'transparent',
    drawerPosition:'right',
    drawerType:'slide'
});

//lastly stack drawer with tabs and modal navigation
const MasterNavigator = createAppContainer(createStackNavigator({
    TabsWithDrawer:{
        screen: TabsWithDrawerNavigation,
    },
    Modal:{
        screen: Modal
    },
    LoginSignup: {
        screen: LoginSignup
    },
    Location:{
        screen: Location
    },
    LocationDetail:{
        screen: LocationDetail
    },
    LocationAdmin:{
        screen: LocationAdmin,
        navigationOptions: {
            gestureEnabled: false,
        },
    },
    Chat: {
        screen: Chat
    },
    Congrats:{
        screen: Congrats
    },
    Instructor:{
        screen: Instructor
    },
    Juicer:{
        screen: Juicer
    }
},{
    mode: 'modal',
    headerMode:'none',
    headerTransparent:true
}));

//////////////////////////////////////////////////
///////////// MAIN CLASS /////////////////////////
const Start = ({COMMON,USER_LOGIN}) =>{
    const [ fontsLoaded, setFontsLoaded ] = useState(false);
    let customFonts = {
        Roboto: require('native-base/Fonts/Roboto.ttf'),
        Roboto_medium: require('native-base/Fonts/Roboto_medium.ttf'),
        Ionicons: require('@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/Ionicons.ttf'),
      };
    //let [fontsLoaded] = useFonts({});
      if (!fontsLoaded) {
          console.log("FONTS not loaded")
        return (
        <AppLoading
            startAsync={() => Font.loadAsync(customFonts)}
            onFinish={() => setFontsLoaded(true)}
            onError={console.warn}/>);
      } else {
    console.log("COMMON",COMMON,USER_LOGIN);
    if(USER_LOGIN && USER_LOGIN.error === null){console.log("Login must be success")}
    ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);
    if(COMMON.walkthroughFinished){
        if(!COMMON.loggedIn){
            return (
                <LoginSignup />
            );
        }else{
            return (
                <MasterNavigator />
            );
        }
    }else{
        return (
            <Walkthrough />
        )
    }
}
}
const mapStateToProps = (state) => ({
    COMMON: state.COMMON,
    USER_LOGIN:state.requests.mutations["USER.LOGIN"]
});

StartApp = connect(mapStateToProps)(Start);


////////////////////////////////////////////////
////////// EXPORT ROOT APP /////////////////////
const Root = () => (
    <Provider store={store}>
        <View style={{flex:1}}>
            <StatusBar 
                backgroundColor="pink"
                barStyle="light-content"
                translucent={true}
            />
            <StartApp />
        </View>
    </Provider>
);
export default Root;