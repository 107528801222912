import devTools from "remote-redux-devtools";
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import logger from 'redux-logger';
import { persistStore, persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native
import { Types } from "./Common";
import axios from 'axios';
import createSagaMiddleware  from 'redux-saga';
import { all, call } from 'redux-saga/effects';
import { handleRequests, success, requestsPromiseMiddleware, sendRequest } from 'redux-saga-requests';
import { createDriver } from 'redux-saga-requests-axios';
import * as actions from './actions';
import DirectusSDK from "@directus/sdk-js";
import { YellowBox } from 'react-native';
import thunk from 'redux-thunk';
import * as SecureStore from 'expo-secure-store';
import { encrypt, decrypt } from 'react-native-simple-encryption';

const directus = new DirectusSDK({
  url: "http://rest.juicedupmedia.com/",
  project: "juicedupmedia"});
  
  export const getDirectus = async () => {
    let tmp1 = await SecureStore.getItemAsync("Juiced3");
    let tmp2 = await SecureStore.getItemAsync("Juiced2");
    let tmp3 = await SecureStore.getItemAsync("Juiced1");

    const password = decrypt(tmp1,tmp3); 
    const email = decrypt(tmp1,tmp2); 
    console.log("Will login into directus after using email",email,"and pw",password,"decrypted with",tmp1)
    let localDirectus = new DirectusSDK();
      await localDirectus.login({
        url: "http://rest.juicedupmedia.com/",
        project: "juicedupmedia",
      email,
    password})
  console.log("Returning directus after using email",email,"and pw",password,"decrypted with",tmp1,"Direcuts is:",localDirectus)
    return localDirectus;
  }
export default function configureStore(onCompletion) {
  
  const persistConfig = {
      key: 'root',
      storage: storage,
    }
    const API_TOKEN = "rrEeaRVcEVOlf7TS3gisjh6P";
    let USER_API_TOKEN = () => {store.getState().COMMON.userToken}
    let userLoginInfo;
    let tmp;
    
    
  const axiosInstance = axios.create({
    baseURL: `http://rest.juicedupmedia.com/juicedupmedia`,
  });
    function* onRequestSaga(request, action) {
      if(request.url == "/auth/authenticate"){
        console.log("####SAGA REQUEST BEGIN FOR ACTION:",action,"With request",request,"Will not use a token as it's a auth request");
        let loginRes = null;
        if(request.data){
        loginRes = directus.login({
          url: "http://rest.juicedupmedia.com/",
          project: "juicedupmedia",
          email: request.data.email,
          password: request.data.password,
        });  
        tmp = request.data.password;
      }
      
        console.log("LoginRes",loginRes);

        loginRes.then(DirectusToken => console.log("directusToken",DirectusToken)).catch(err => console.log("directusLoginErr",err));
        
        return request
      }

      else{

        if(directus.refreshIfNeeded()){
          const OldToken = store.getState().COMMON.userToken;
          console.log("refreshIfNeeded - State token",store.getState().COMMON.userToken)
          console.log("refreshIfNeeded - Will refresh with DirectusSDK")
          
            console.log("Got oldtoken:",OldToken)
            const directusRefresh = directus.refresh(OldToken);
          yield directusRefresh.then(data => {
              console.log("refreshIfNeeded - Got new token",data.data.token)
              //USER_API_TOKEN = data.data.token;
              store.dispatch(actions.refreshToken(data.data.token));
              return request;
            }).catch(err => console.log("refreshIfNeeded - DirectusRefreshErr",err));
        }
          console.log("####SAGA REQUEST BEGIN FOR ACTION:",action,"With request",request,"Will use token:",store.getState().COMMON.userToken,"END!#!")
          
            request.headers = {"Authorization": `Bearer ${store.getState().COMMON.userToken}`,"X-Directus-Project": "juicedupmedia"};
          
          return request;
        
      }
    }
    
    function* onSuccessSaga(response, action) {
      if(action.type == "USER.LOGIN" && response.data.data.token){
        console.log("User has successfully logged in - Will update token for future requests" )
        //USER_API_TOKEN = response.data.data.token;
        userLoginInfo = response.data.data;
        store.dispatch(actions.refreshToken(response.data.data));
        yield SecureStore.setItemAsync("Juiced1",encrypt(response.data.data.user.external_id,tmp));
        yield SecureStore.setItemAsync("Juiced2",encrypt(response.data.data.user.external_id,response.data.data.user.email));
        yield SecureStore.setItemAsync("Juiced3",response.data.data.user.external_id);
        tmp = "";
      }
      if(action.type == "USER.LOGOUT"){
        console.log("User has successfully logged OUT")
        store.dispatch(actions.refreshToken("LOGGED OUT"));
      }
      return response;
    }
    
    function* onErrorSaga(error, action) {
      try {
        const errorCode = error.toString().match(/\d/g).join("")
      console.log("Error code:", errorCode)
      console.log("SAGA REQUEST ERROR for action:",action,"with error",error)
      
      if (errorCode == 401) {
        console.log("User denied - maybe token has expired - will try again")
    
        try {
          const OldToken = store.getState().COMMON.userToken;
          console.log("onErrorSaga(401) - State token",store.getState().COMMON.userToken)
          console.log("onErrorSaga(401) - Will refresh with DirectusSDK")
          
            console.log("onErrorSaga(401) - Got oldtoken:",OldToken)
            const directusRefresh = directus.refresh(OldToken);
          yield directusRefresh.then(data => {
              console.log("onErrorSaga(401) - Got new token",data.data.token)
              store.dispatch(actions.refreshToken(data.data.token));
              return call(sendRequest, action, { silent: true });
            }).catch(err => {
              console.log("onErrorSaga(401) - DirectusRefreshErr",err)
            if(err.toString().includes("Expired token")){
              console.log("onErrorSaga(401) - Error is that the token has expired")
              try {
                const {email} = store.getState().COMMON.userLoginInfo.response.data.data.user;
                SecureStore.getItemAsync("Juiced1").then(tmp2 => {
                  tmp = decrypt(store.getState().COMMON.userLoginInfo.response.data.data.user.external_id,tmp2)
                  console.log("onErrorSaga(401) - State email,pw",email,tmp,store.getState().COMMON.userLoginInfo,tmp2)
                  console.log("onErrorSaga(401) - Will refresh with DirectusSDK")
                
                  console.log("onErrorSaga(401) - Got oldtoken:",OldToken)
                  const directusLogin = directus.login({email,password:tmp});
                  directusLogin.then(data => {
                    console.log("onErrorSaga(401) - Got new login token",data.data.token)
                    store.dispatch(actions.refreshToken(data.data.token));
                    return call(sendRequest, action, { silent: true });
                  }).catch(err1 => console.log("directusLogin2Err",err1));
                }).catch(err2 => console.log("SecureStorage-err",err2));
  
              } catch(e) {
                console.log("onErrorSaga(401) - ERROR-TRY-ERR",e);
                return { error: e }
              }
          }
          
          });
          console.log("onErrorSaga(401) - Will try and send the failed request again")
          return yield call(sendRequest, action, { silent: true });
  
        } catch(e) {
          console.log("onErrorSaga(401) - ERROR-TRY-ERR",e);
          // we didnt manage to get a new token
          return { error: e }
        }
      }
      if (errorCode == 403) {
        console.log("onErrorSaga(403) - User denied - maybe token is too old - will try and login user")
    
        try {
          SecureStore.getItemAsync("Juiced1").then(tmp2 => {
            tmp = decrypt(store.getState().COMMON.userLoginInfo.response.data.data.user.external_id,tmp2)
          
          const {email, password} = store.getState().COMMON.userLoginInfo.response.data.data.user;
          console.log("onErrorSaga(403) - State token",store.getState().COMMON.userToken)
          console.log("onErrorSaga(403) - Will refresh with DirectusSDK")
          
            console.log("onErrorSaga(403) - Got oldtoken(403):",OldToken)
            const directusLogin = directus.login({email,password:tmp});
                  directusLogin.then(data => {
                    console.log("onErrorSaga(403) - Got new login token",data.data.token)
                    store.dispatch(actions.refreshToken(data.data.token));
                    return call(sendRequest, action, { silent: true });
                  }).catch(err1 => console.log("onErrorSaga(403) - directusLogin2Err",err1));
                }).catch(err2 => console.log("onErrorSaga(403) - SecureStorage-err",err2));
          
          return yield call(sendRequest, action, { silent: true });
  
        } catch(e) {
          console.log("onErrorSaga(403) - ERROR-TRY-ERR",e);
          // we didnt manage to get a new token
          return { error: e }
        }
      }
      return call(sendRequest, action, { silent: true });
      } catch (tryError) {
        console.log("onErrorSaga(403) - Internal Store error",tryError)
      }
      
    
      // not related token error, we pass it like nothing happened
      return { error };
    }
    
    function* onAbortSaga(action) {
      console.log("SAGA REQUEST ABORT",action)
    }


    const { requestsReducer, requestsSagas } = handleRequests({
      driver: createDriver(axiosInstance),
      onRequest: onRequestSaga,
   onSuccess: onSuccessSaga,
   onError: onErrorSaga,
   onAbort: onAbortSaga,
   autoPromisify: true,
   normalize:false,
   promisify:true,
    });
    const sagaMiddleware = createSagaMiddleware();
    const middlewares = [thunk,requestsPromiseMiddleware];
    
    if (process.env.NODE_ENV === 'development') {
        middlewares.push(logger);
        YellowBox.ignoreWarnings([
            'NetInfo\'s "change" event is deprecated',
            'Warning:',
            'You should only render'
        ]);
    }else{
        //disable all console errors..
    }
      const enhancer = compose(
        applyMiddleware(sagaMiddleware),
        devTools({
          name: "MyApp",
          realtime: true
        })
      );
      const defaultStates = {
        "COMMON": {
            "walkthroughFinished": false,
            "loggedIn": false,
        }
    }
    
    const commonReducer = (state = defaultStates["COMMON"], action) => {
        switch (action.type) {
            case Types.COMMON.GET_DIRECTUS:
              console.log("WILL RETURN DIRECTUS FROM REDUCER")
              return {...state,directus};
            break;
            case Types.COMMON.BEGIN_WALKTHROUGH:
                return { ...state, walkthroughFinished: false, loggedIn: false };
            break;
            case Types.COMMON.FINISH_WALKTHROUGH:
                return { ...state, walkthroughFinished: true };
            break;
            case success(Types.USER.LOGIN):
              return { ...state, loggedIn: true, userLoginInfo:action, userToken:action.response.data.data.token };
            break;
            case Types.USER.REFRESH_TOKEN:
              console.log("REFRESH_TOKEN_REDUCSER",action.payload)
              return { ...state, userToken:action.payload };
            break;
            case Types.USER.LOGOUT:
                return Object.assign({}, state, {
                    USER_LOGIN: [
                      ...state.USER_LOGIN,
                      {
                        data: null,
                      }
                    ]
                  })
            break;
          default:
            return state;
        }
      };
      const reducers = combineReducers({
        requests: requestsReducer,
        COMMON:commonReducer
       });
      const persistedReducer = persistReducer(persistConfig, reducers);
      let store = createStore(persistedReducer, enhancer);
      let persistor = persistStore(store, null, onCompletion);
    function* rootSaga() {
     yield all(requestsSagas);
    }
    sagaMiddleware.run(rootSaga, axiosInstance);
    if(false)persistor.purge();
    return store;
  }
