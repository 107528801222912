import React, { Component } from 'react';
import Start from './src/Start';
import ErrorBoundary from './ErrorBoundary';

// create a component
class App extends Component {
    render() {
        return ( 
                <Start />
        );
    }
}
export default App;

