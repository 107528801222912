import * as Localization from "expo-localization";
import i18n from "i18n-js";
import { StyleSheet, Dimensions, Platform } from "react-native";
import { createTypes } from "redux-create-types";

/*
Expo.Font.loadAsync({
  Helvetica: require('./assets/fonts/HELR45W.ttf')
});
*/

const Fonts = {
  //family:{
  //  _default:'Helvetica',
  //},
  size: {
    _default: 14,
    h1: 30,
    h2: 28,
    h3: 26,
    h4: 22,
    h5: 20,
    h6: 18,
    h7: 16,
    h8: 14,
    h9: 12,
    h10: 10,
    labelSize: 14
  }
};

const Images = {
  medicine: require("./assets/images/ARCH.png"),
  guitar: require("./assets/images/CafeValentin.png"),
  business: require("./assets/images/OldIrish.png"),
  profile: require("./assets/images/Picture.png"),
  locationbg: require("./assets/images/locationbg.png"),
  locationcv: require("./assets/images/Cover.png"),
  confetti: require("./assets/images/Confetti.png"),
  icons: {
    logo: require("./assets/images/icons/LogoIcon.png"),
    business: require("./assets/images/icons/006-travel.png"),
    design: require("./assets/images/icons/002-edit-tools.png"),
    economy: require("./assets/images/icons/004-pie-chart.png"),
    database: require("./assets/images/icons/004-database.png"),
    literature: require("./assets/images/icons/003-book.png"),
    science: require("./assets/images/icons/002-medical.png"),
    favorite: require("./assets/images/icons/favorite.png"),
    friends: require("./assets/images/icons/friends.png"),
    achieve: require("./assets/images/icons/achieve.png"),
    interest: require("./assets/images/icons/interest.png"),
    key: require("./assets/images/icons/key.png"),
    blocked: require("./assets/images/icons/blocked.png"),
    location: require("./assets/images/icons/location.png"),
    juicers: require("./assets/images/icons/icousers.png"),
    ratings: require("./assets/images/icons/icorating.png"),
    lectures: require("./assets/images/icons/icolecture.png"),
    download: require("./assets/images/icons/download.png"),
    sharegray: require("./assets/images/icons/sharegray.png"),
    achievegray: require("./assets/images/icons/achievegray.png")
  },
  badges: {
    starter: require("./assets/images/badges/medal1.png"),
    elite: require("./assets/images/badges/medal2.png"),
    geek: require("./assets/images/badges/medal3.png"),
    pro: require("./assets/images/badges/medal4.png")
  },
  users: {
    user1: require("./assets/images/users/596.jpg"),
    user2: require("./assets/images/users/605.jpg"),
    user3: require("./assets/images/users/613.jpg"),
    user4: require("./assets/images/users/622.jpg"),
    user5: require("./assets/images/users/627.jpg"),
    user6: require("./assets/images/users/631.jpg")
  }
};
/*
const Colors = {
  _default:'#FFF',
  primary:'#ddc7b5',
  primaryLight:'#ef6e03',
  primaryAccentLight:'rgb(36, 62, 94)',
  primaryAccent: '#000',
  secondary:'#ef6e03',
  textPrimary: '#FFF',
  textSecondary: '#FFF',
  separator: '#FFF',
  separator1: '#000',
  yellowish:'#ef6e03'
};*/

const Colors = {
  _default: "green",
  primary: "#ddc7b5",
  primaryLight: "#ef6e03",
  primaryAccentLight: "rgb(36, 62, 94)",
  primaryAccent: "#000",
  secondary: "#4b4a48",
  textPrimary: "red",
  textSecondary: "yellow",
  separator: "blue",
  separator1: "#000",
  yellowish: "#ef6e03"
};

const Styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.secondary
  },
  safeArea: {
    flex: 1,

    backgroundColor: "transparent" //Colors.secondary
  },
  mainContent: {
    flex: 1,

    backgroundColor: "transparent" //Colors.secondary
  },
  appHeader: {
    minHeight: 60,
    paddingTop: Platform.OS === "android" ? 25 : 0,
    backgroundColor: Colors.secondary,
    justifyContent: "center",
    paddingHorizontal: 6
  },
  appHeaderLogoText: {
    fontSize: 22,
    color: "#FFF",
    fontWeight: "bold"
  },
  containerNoHeader: {
    paddingTop: 40,
    flex: 1,
    paddingHorizontal: 8,
    backgroundColor: Colors.secondary
  },
  containerAfterHeader: {
    flex: 1,
    paddingHorizontal: 8
  },
  headerTitle: {
    color: "#FFF",
    fontSize: 14,
    fontWeight: "bold",
    marginTop: 10,
    paddingBottom: 6
  },
  featuredHolder: {
    margin: 0
  },
  featuredHolderImg: {
    height: 80,
    width: 210,
    marginHorizontal: 0,
    borderRadius: 15,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    overflow: "hidden"
  },
  featuredImage: {
    height: 80,
    width: 210
  },
  featuredHolderTitleHolder: {
    width: 210,
    padding: 0
  },
  featuredHolderTitle: {
    color: "#eee",
    fontSize: 12,
    paddingBottom: 10
  },
  featuredHolderPrice: {
    textAlign: "center"
  },

  //icon style
  iconStyle: {},

  /////MY COURSES
  gridContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-start",
    flexWrap: "wrap"
  },

  grid2column: {
    width: "50%",
    height: 200,
    justifyContent: "center",
    alignItems: "center"
  },

  grid_i_container: {
    margin: 4,
    backgroundColor: "red"
  },

  ////SEARCH PAGE
  searchSection: {
    paddingVertical: 10
  },
  searchData: {
    paddingVertical: 10
  },
  searchTC: {},
  i_c: {
    marginRight: 20,
    alignItems: "center"
  },
  i_p: {
    width: 70,
    height: 70,
    borderRadius: 35,
    marginBottom: 8
  },
  i_a_o: {
    position: "absolute",
    width: 18,
    height: 18,
    backgroundColor: "yellow",
    top: 0,
    right: 2,
    zIndex: 3,
    borderRadius: 10
  },
  i_a_o_c: {
    position: "absolute",
    width: 18,
    height: 18,
    backgroundColor: "grey",
    top: 0,
    right: 0,
    zIndex: 3,
    borderRadius: 10
  },
  i_o: {
    flex: 1,
    margin: 3,
    borderRadius: 8,
    backgroundColor: "green"
  },
  c_i_o: {
    flex: 1,
    margin: 3,
    borderRadius: 8,
    backgroundColor: "green"
  },
  c_i_n: {
    flex: 1,
    margin: 3,
    borderRadius: 8,
    backgroundColor: "pink"
  },
  i_tc: {
    color: "orange",
    fontWeight: "bold"
  },
  i_cc: {
    color: "#888"
  },
  gridCategories: {
    width: Dimensions.get("screen").width / 3 - 22,
    height: Dimensions.get("screen").width / 3 - 22,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.primaryAccentLight,
    borderRadius: Dimensions.get("screen").width / 3
  },
  gridWrapInnerContainer: {
    margin: 4,
    marginBottom: 10,
    padding: 4
  },
  gridWrapContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start"
  },
  gridCatTitle: {
    color: "#FFF",
    textAlign: "center",
    fontWeight: "bold",
    paddingVertical: 6
  },

  //Homepage Latest Styles
  latestHolder: {
    flexDirection: "row",
    marginVertical: 0
  },
  latestImage: {
    height: "100%",
    width: 130,
    borderRadius: 15,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    overflow: "hidden",
    backgroundColor: Colors._default
  },
  //latest cover style
  latestCover: {
    height: 160,
    width: 130
  },
  latestContentHolder: {
    marginHorizontal: 8,
    width: Dimensions.get("screen").width - 150,
    justifyContent: "space-between",
    paddingVertical: 3
  },
  latestTitle: {
    fontSize: 18,
    color: "#eee",
    marginBottom: 15
  },
  latestListH: {
    fontSize: 18,
    color: "#eee",
    textAlign: "center"
  },
  latestListB: {
    color: Colors.textSecondary,
    textAlign: "center"
  },

  ///Chats
  chatOuter: {},

  //badge
  badgeHolder: {
    flexDirection: "row",
    marginTop: 6
  },
  badge: {
    marginRight: 6
  },
  profilePhoto: {
    width: 100,
    marginRight: 10,
    marginTop: 10
  },
  settingIcon: {
    paddingVertical: 10
  },
  settingContent: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottomWidth: 1,
    borderBottomColor: Colors.separator,
    paddingTop: 8,
    paddingBottom: 13
  },
  settingItem: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  settingLabel: {
    color: Colors.textPrimary,
    fontSize: Fonts.size.h6,
    paddingLeft: 6
  },
  c_p: {
    width: 50,
    height: 50,
    borderRadius: 25,
    marginBottom: 8
  },
  cri: {
    alignItems: "center",
    justifyContent: "center"
  },
  crg: {},
  crth: {
    color: Colors.textPrimary,
    fontSize: Fonts.size.h4
  },
  crtp: {
    color: Colors.textSecondary,
    fontSize: Fonts.size.h7
  },
  itm: {
    flex: 1
  },
  itb: {
    position: "absolute",
    left: 0,
    bottom: 6,
    right: 0,
    borderBottomWidth: 1,
    borderBottomColor: Colors.separator1
  },
  ptt: {
    color: Colors.textPrimary,
    textAlign: "center",
    fontSize: Fonts.size.h5,
    fontWeight: "bold"
  },
  ptb: {
    color: Colors.textSecondary,
    textAlign: "center"
  }
});

/*********
 * Redux *
 *********/

/********************
 * Internal objects *
 ********************/

const Types = {
  USER: createTypes(
    ["CREATE", "GET_INFO", "LOGIN", "LOGOUT", "UPDATE_INFO", "FORGOT_PASSWORD","REFRESH_TOKEN"],
    "USER"
  ),
  OFFER: createTypes(
    ["FETCH_ALL", "FETCH_ACCEPTED", "FETCH_FILTERED"],
    "OFFER"
  ),
  LOCATION: createTypes(
    ["FETCH_ALL", "FETCH_RATINGS", "FETCH_FAVORITES", "FETCH_LOCATION_ADMIN"],
    "LOCATION"
  ),
  FRIENDS: createTypes(["FETCH_ALL", "FETCH_MY_FRIENDS"], "FRIENDS"),
  COMMON: createTypes(
    ["BEGIN_WALKTHROUGH", "FINISH_WALKTHROUGH", "USER_LOGGED_IN","GET_DIRECTUS"],
    "COMMON"
  )
};

/****************
 * TRANSLATIONS *
 ****************/

// Set the key-value pairs for the different languages you want to support.
i18n.translations = {
  en: {
    welcome: "Hello",
    loading_data: "... loading",
    loading_data_failed: "An error happend while fetching data.",
    offer_details_tab_about: 'About',
    offer_details_tab_map: 'Map',
    offer_details_about_header: 'About the place',
    new_locations_header: 'Newest locations',
    latest_offers_header: 'Latest offers'
  },
  da: { welcome: "Velkommen", loading_data: "... henter" }
};
// Set the locale once at the beginning of your app.
i18n.locale = Localization.locale;
// When a value is missing from a language it'll fallback to another language with the key present.
i18n.fallbacks = true;

const Translation = {
  get: key => i18n.t(key)
};
export { Styles, Fonts, Colors, Images, Types, Translation };
