import React, { Component } from 'react';
import { 
    Button,
    View, 
    Text,
    Image,
    TouchableOpacity,
    SafeAreaView
} from 'react-native';

import * as actions from './actions';
import { connect } from 'react-redux';
import { Query } from "redux-saga-requests-react";
import { Colors, Styles, Images, Fonts, Types, Translation } from './Common';
import TakeerText from './components/TakeerText';
import TakeerIcon from './components/TakeerIcon';

const LoadingComponent = ({ label }) => (
    null
  );
  
  const ErrorComponent = ({ error, label }) => {
    return (
      null
    );
  };

class Drawer extends Component {
    /*constructor(props) {
        super(props);
        console.log("DrawerProps",this.props)
        //this.props.fetchAdminLocations(userId);
    }*/
    componentDidMount(){
        try {
            if(this.props.data){

                if(this.props.data.COMMON.userLoginInfo.response.data.data.user.id){
                    console.log("DrawerProps",this.props.data.COMMON.userLoginInfo.response.data.data.user.id);
                    this.props.fetchAdminLocations(this.props.data.COMMON.userLoginInfo.response.data.data.user.id);
                    }
                }    
        } catch (error) {
            console.log("Drawer-Err-ComponentDidMount",error)
        }
        
    }
    render() {
        const { navigate } = this.props.navigation;
        
        return (
            <View style={[Styles.container, { paddingTop:30, flexDirection:'column', justifyContent: 'space-between' }]}>
                <View style={{flex:1, paddingHorizontal:10}}>
                <View style={{flexDirection:'row'}}>
                    <View style={Styles.profilePhoto}>
                        <Image source={Images.profile} style={{borderRadius:50}}/>
                    </View>
                    <View>
                        <View>
                            <TakeerText style={Styles.headerTitle}>Nimasha Perera</TakeerText>
                        </View>
                        <View>
                            <TakeerText style={{fontSize:Fonts.size.h8, color: Colors.textSecondary }}>390,329 Points</TakeerText>
                        </View>
                    </View>
                </View>

                <View style={{marginTop:30, borderBottomWidth:1, borderBottomColor: Colors.separator, paddingBottom:6}}>
                    <TakeerText style={{
                        color: Colors.textSecondary,
                        fontSize: Fonts.size.h7
                    }}>GENERAL</TakeerText>
                </View>
                <TouchableOpacity style={Styles.settingItem}>
                    <View style={Styles.settingIcon}>
                        <Image source={Images.icons.favorite}/>
                    </View>
                    <View style={Styles.settingContent}>
                        <View>
                            <TakeerText style={Styles.settingLabel}>Favourite Locations</TakeerText>
                        </View>
                        <TakeerIcon
                            iconType="Entypo"
                            iconName="chevron-small-right"
                            iconSize={20}
                            iconColor={Colors.textSecondary}
                        />
                    </View>
                </TouchableOpacity>

                <TouchableOpacity style={Styles.settingItem}>
                    <View style={Styles.settingIcon}>
                        <Image source={Images.icons.friends}/>
                    </View>
                    <View style={Styles.settingContent}>
                        <View>
                            <TakeerText style={Styles.settingLabel}>My Friends</TakeerText>
                        </View>
                        <View style={{
                            backgroundColor: Colors.primaryAccent,
                            paddingHorizontal:10,
                            borderRadius:30,
                            paddingVertical:2,
                        }}>
                            <TakeerText style={{color: Colors.textPrimary}}>
                                50+
                            </TakeerText>
                        </View>
                    </View>
                </TouchableOpacity>

                <View style={{marginTop:30, borderBottomWidth:1, borderBottomColor: Colors.separator, paddingBottom:6}}>
                    <TakeerText style={{
                        color: Colors.textSecondary,
                        fontSize: Fonts.size.h7
                    }}>SETTINGS</TakeerText>
                </View>
                <TouchableOpacity style={Styles.settingItem}>
                    <View style={Styles.settingIcon}>
                        <Image source={Images.icons.key}/>
                    </View>
                    <View style={Styles.settingContent}>
                        <View>
                            <TakeerText style={Styles.settingLabel}>Edit Login Details</TakeerText>
                        </View>
                        <TakeerIcon
                            iconType="Entypo"
                            iconName="chevron-small-right"
                            iconSize={20}
                            iconColor={Colors.textSecondary}
                        />
                    </View>
                </TouchableOpacity>

                <TouchableOpacity style={Styles.settingItem}>
                    <View style={Styles.settingIcon}>
                        <Image source={Images.icons.interest}/>
                    </View>
                    <View style={Styles.settingContent}>
                        <View>
                            <TakeerText style={Styles.settingLabel}>Update Interests</TakeerText>
                        </View>
                        <TakeerIcon
                            iconType="Entypo"
                            iconName="chevron-small-right"
                            iconSize={20}
                            iconColor={Colors.textSecondary}
                        />
                    </View>
                </TouchableOpacity>

                <TouchableOpacity style={Styles.settingItem}>
                    <View style={Styles.settingIcon}>
                        <Image source={Images.icons.blocked}/>
                    </View>
                    <View style={Styles.settingContent}>
                        <View>
                            <TakeerText style={Styles.settingLabel}>Blocked Users</TakeerText>
                        </View>
                        <TakeerIcon
                            iconType="Entypo"
                            iconName="chevron-small-right"
                            iconSize={20}
                            iconColor={Colors.textSecondary}
                        />
                    </View>
                </TouchableOpacity>
                <Query
              type={Types.LOCATION.FETCH_LOCATION_ADMIN}
              // or selector={myQuerySelector}
              isDataEmpty={query =>
                Array.isArray(query.data)
                  ? query.data.length === 0
                  : !query.data
              }
              showLoaderDuringRefetch={false}
              noDataMessage={<Text>There is no data yet</Text>}
              errorComponent={ErrorComponent}
              errorComponentProps={{ label: "Error label" }}
              loadingComponent={LoadingComponent}
              loadingComponentProps={{ label: "Loading label" }}
            >
              {data => {
                  data = data.data.data
                  console.log("LocationAdminData",data);
                  console.log("This user admins",data.length," locations")
                    if(data.length){
                  return(
                <TouchableOpacity style={Styles.settingItem} onPress={() => navigate('LocationAdmin',data)}>
                    <View style={Styles.settingIcon}>
                        <Image source={Images.icons.achieve}/>
                    </View>
                    <View style={Styles.settingContent}>
                        <View>
                            <TakeerText style={Styles.settingLabel}>Manage location{(data.length == 1) ? "":"s"}</TakeerText>
                        </View>
                        <TakeerIcon
                            iconType="Entypo"
                            iconName="chevron-small-right"
                            iconSize={20}
                            iconColor={Colors.textSecondary}
                        />
                    </View>
                </TouchableOpacity>
              )}
              else {
                  return null
              }}}
            </Query>
                </View>
                <TouchableOpacity style={{backgroundColor: Colors.secondary, paddingVertical:10, alignItems:'center'}}
                onPress={()=>{this.props.beginWalkthrough(); this.props}}
                >
                    <View style={{flexDirection:'row', alignItems:'center'}}>
                        <TakeerIcon
                            iconName="ios-exit"
                            iconType="Ionicons"
                            iconColor={Colors._default}
                            iconSize={20}
                        />
                        <TakeerText style={{paddingLeft:4}}>Logout</TakeerText>
                    </View>
                </TouchableOpacity>
            </View>
        );
    }
}

const mapStateToProps = ( state ) => ({
    settings: state.settings,
    data: state
});

export default connect(mapStateToProps,actions)(Drawer);

/*
import React, { Component } from 'react';
import { 
    View, 
    Text,
    Image,
    TouchableOpacity,
    SafeAreaView
} from 'react-native';
import * as actions from '../../actions';
import { connect } from 'react-redux';
import { Colors, Styles, Images, Fonts } from '../../Common';
import TakeerText from '../../components/TakeerText';
import TakeerIcon from '../../components/TakeerIcon';

class TabProfile extends Component {

    render() {
        return (
            <SafeAreaView style={Styles.safeArea}>
            <View style={Styles.containerNoHeader}>
                <View style={{flexDirection:'row'}}>
                    <View style={Styles.profilePhoto}>
                        <Image source={Images.profile} style={{borderRadius:50}}/>
                    </View>
                    <View>
                        <View>
                            <TakeerText style={Styles.headerTitle}>Nimasha Perera</TakeerText>
                        </View>
                        <View>
                            <TakeerText style={{fontSize:Fonts.size.h6, color: Colors.textSecondary }}>390,329 Points</TakeerText>
                        </View>
                        <View style={Styles.badgeHolder}>
                            <View style={Styles.badge}>
                                <Image source={Images.badges.starter}/>
                            </View>
                            <View style={Styles.badge}>
                                <Image source={Images.badges.elite}/>
                            </View>
                            <View style={Styles.badge}>
                                <Image source={Images.badges.geek}/>
                            </View>
                            <View style={Styles.badge}>
                                <Image source={Images.badges.pro}/>
                            </View>
                        </View>
                    </View>
                </View>

                <View style={{marginTop:30, borderBottomWidth:1, borderBottomColor: Colors.separator, paddingBottom:6}}>
                    <TakeerText style={{
                        color: Colors.textSecondary,
                        fontSize: Fonts.size.h7
                    }}>GENERAL</TakeerText>
                </View>
                <TouchableOpacity style={Styles.settingItem}>
                    <View style={Styles.settingIcon}>
                        <Image source={Images.icons.favorite}/>
                    </View>
                    <View style={Styles.settingContent}>
                        <View>
                            <TakeerText style={Styles.settingLabel}>Favourite Locations</TakeerText>
                        </View>
                        <TakeerIcon
                            iconType="Entypo"
                            iconName="chevron-small-right"
                            iconSize={20}
                            iconColor={Colors.textSecondary}
                        />
                    </View>
                </TouchableOpacity>

                <TouchableOpacity style={Styles.settingItem}>
                    <View style={Styles.settingIcon}>
                        <Image source={Images.icons.friends}/>
                    </View>
                    <View style={Styles.settingContent}>
                        <View>
                            <TakeerText style={Styles.settingLabel}>My Friends</TakeerText>
                        </View>
                        <View style={{
                            backgroundColor: Colors.primaryAccent,
                            paddingHorizontal:10,
                            borderRadius:30,
                            paddingVertical:2,
                        }}>
                            <TakeerText style={{color: Colors.textPrimary}}>
                                50+
                            </TakeerText>
                        </View>
                    </View>
                </TouchableOpacity>

                <TouchableOpacity style={Styles.settingItem}>
                    <View style={Styles.settingIcon}>
                        <Image source={Images.icons.achieve}/>
                    </View>
                    <View style={Styles.settingContent}>
                        <View>
                            <TakeerText style={Styles.settingLabel}>Achievements</TakeerText>
                        </View>
                        <TakeerIcon
                            iconType="Entypo"
                            iconName="chevron-small-right"
                            iconSize={20}
                            iconColor={Colors.textSecondary}
                        />
                    </View>
                </TouchableOpacity>



                <View style={{marginTop:30, borderBottomWidth:1, borderBottomColor: Colors.separator, paddingBottom:6}}>
                    <TakeerText style={{
                        color: Colors.textSecondary,
                        fontSize: Fonts.size.h7
                    }}>SETTINGS</TakeerText>
                </View>
                <TouchableOpacity style={Styles.settingItem}>
                    <View style={Styles.settingIcon}>
                        <Image source={Images.icons.key}/>
                    </View>
                    <View style={Styles.settingContent}>
                        <View>
                            <TakeerText style={Styles.settingLabel}>Edit Login Details</TakeerText>
                        </View>
                        <TakeerIcon
                            iconType="Entypo"
                            iconName="chevron-small-right"
                            iconSize={20}
                            iconColor={Colors.textSecondary}
                        />
                    </View>
                </TouchableOpacity>

                <TouchableOpacity style={Styles.settingItem}>
                    <View style={Styles.settingIcon}>
                        <Image source={Images.icons.interest}/>
                    </View>
                    <View style={Styles.settingContent}>
                        <View>
                            <TakeerText style={Styles.settingLabel}>Update Interests</TakeerText>
                        </View>
                        <TakeerIcon
                            iconType="Entypo"
                            iconName="chevron-small-right"
                            iconSize={20}
                            iconColor={Colors.textSecondary}
                        />
                    </View>
                </TouchableOpacity>

                <TouchableOpacity style={Styles.settingItem}>
                    <View style={Styles.settingIcon}>
                        <Image source={Images.icons.blocked}/>
                    </View>
                    <View style={Styles.settingContent}>
                        <View>
                            <TakeerText style={Styles.settingLabel}>Blocked Users</TakeerText>
                        </View>
                        <TakeerIcon
                            iconType="Entypo"
                            iconName="chevron-small-right"
                            iconSize={20}
                            iconColor={Colors.textSecondary}
                        />
                    </View>
                </TouchableOpacity>
            </View>
            </SafeAreaView>
        );
    }
}

const mapStateToProps = ( state ) => ({
    settings: state.settings
})
export default connect(mapStateToProps,actions)(TabProfile);*/