import React, { Component } from 'react';
import { View, Text, TextInput, TouchableOpacity, StatusBar } from 'react-native';
import styles from './styles';
import TakeerIcon from '../../components/TakeerIcon';
import TakeerButton from '../../components/TakeerButton';
import TakeerText from '../../components/TakeerText';
import * as actions from '../../actions';
import { connect } from 'react-redux';
import { Colors } from '../../Common';

class SignupComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {"email":"ceo3@juicedupmedia.com",password:"123456","fullName":"Henning","username":"Honningkrukke"};
    }

    signUp=()=>{
        this.props.createUser(this.state.email,this.state.password,this.state.firstName,this.state.lastName);
    }

    render() {
        return (
            <View>
                <View style={styles.iTxt}>
                    <View style={styles.iIcon}>
                        <TakeerIcon
                            iconType= "Ionicons"
                            iconName="ios-person"
                            iconSize={22}
                            iconColor="black"
                            iconPosition=""
                        />
                    </View>
                    <View style={styles.iInput}>
                        <TextInput
                            placeholder="Username"
                            placeholderTextColor="black"
                            underlineColorAndroid="transparent"
                            style={{}}
                            onChangeText={(text) => this.setState({username: text})}
                        />
                    </View>
                </View>
                <View style={styles.iTxt}>
                    <View style={styles.iIcon}>
                        <TakeerIcon
                            iconType= "Ionicons"
                            iconName="ios-person"
                            iconSize={22}
                            iconColor="black"
                            iconPosition=""
                        />
                    </View>
                    <View style={styles.iInput}>
                        <TextInput
                            placeholder="Full Name"
                            placeholderTextColor="black"
                            underlineColorAndroid="transparent"
                            style={{}}
                            onChangeText={(text) => this.setState({fullName: text})}
                        />
                    </View>
                </View>

                <View style={styles.iTxt}>
                    <View style={styles.iIcon}>
                        <TakeerIcon
                            iconType= "Ionicons"
                            iconName="ios-mail"
                            iconSize={22}
                            iconColor="black"
                            iconPosition=""
                        />
                    </View>
                    <View style={styles.iInput}>
                        <TextInput
                            placeholder="Email"
                            placeholderTextColor="black"
                            underlineColorAndroid="transparent"
                            keyboardType="email-address"
                            style={styles.input}
                            onChangeText={(text) => this.setState({email: text})}
                        />
                    </View>
                </View>
                <View style={styles.iTxt}>
                    <View style={styles.iIcon}>
                        <TakeerIcon
                            iconType= "Ionicons"
                            iconName="ios-mail"
                            iconSize={22}
                            iconColor="black"
                            iconPosition=""
                        />
                    </View>
                    <View style={styles.iInput}>
                        <TextInput
                            placeholder="Repeat email"
                            placeholderTextColor="black"
                            underlineColorAndroid="transparent"
                            keyboardType="email-address"
                            style={styles.input}
                            onChangeText={(text) => this.setState({repeatemail: text})}
                        />
                    </View>
                </View>
                <View style={styles.iTxt}>
                    <View style={styles.iIcon}>
                        <TakeerIcon
                            iconType= "Ionicons"
                            iconName="ios-lock"
                            iconSize={22}
                            iconColor="black"
                            iconPosition=""
                        />
                    </View>
                    <View style={styles.iInput}>
                        <TextInput
                            placeholder="Password"
                            secureTextEntry={true}
                            placeholderTextColor="black"
                            underlineColorAndroid="transparent"
                            style={styles.input}
                            onChangeText={(text) => this.setState({password: text})}
                        />
                    </View>
                </View>

                <View style={styles.iTxt}>
                    <View style={styles.iIcon}>
                        <TakeerIcon
                            iconType= "Ionicons"
                            iconName="ios-call"
                            iconSize={22}
                            iconColor="black"
                            iconPosition=""
                        />
                    </View>
                    <View style={styles.iInput}>
                        <TextInput
                            placeholder="Gender"
                            placeholderTextColor="black"
                            underlineColorAndroid="transparent"
                            keyboardType="text"
                            style={styles.input}
                            onChangeText={(text) => this.setState({gender: text})}
                        />
                    </View>
                </View>

                <View style={styles.iTxt}>
                    <View style={styles.iIcon}>
                        <TakeerIcon
                            iconType= "Ionicons"
                            iconName="ios-call"
                            iconSize={22}
                            iconColor="black"
                            iconPosition=""
                        />
                    </View>
                    <View style={styles.iInput}>
                        <TextInput
                            placeholder="Zip Code"
                            placeholderTextColor="black"
                            underlineColorAndroid="transparent"
                            keyboardType="text"
                            style={styles.input}
                            onChangeText={(text) => this.setState({zipCode: text})}
                        />
                    </View>
                </View>

                <View style={{marginHorizontal:'10%'}}>
                    <TakeerButton
                        onPress={this.signUp}
                        backgroundColor={Colors.primaryAccent}
                        borderWidth={1}
                        borderColor="transparent"
                        borderRadius={30} // pass border radius
                        textColor="#fff"
                        textBold={false}
                        textItalic={false}
                        textSize={14}
                        textFont=""
                        text="SIGNUP NOW" //button texts
                        showIcon={false}// if false, pass null to every icon attribute below
                        iconType="Ionicons" //Ionicons,Entypo, EvilIcons, FontAwesome, MaterialCommunityIcons, MaterialIcons, Octicons, SimpleLineIcons, Zocial, null
                        iconName="ios-people" //icon name according to iconType or pass null to hide
                        iconSize={30}
                        iconColor="#fff"
                        iconPosition="left" //left, right, null
                        loading={false} //true or false -- true to show spinner/loading
                        loadingText="" // default is Loading.., you may pass any texts or null not to show
                        paddingHorizontal={10}// 
                    />                        
                </View>
                <View style={{flexDirection:'row', alignSelf:'center', marginVertical:30}}>
                    <View>
                        <TakeerText style={{color:'#F5F5F5'}}>Already have an Account ? </TakeerText>
                    </View>
                    <View>
                        <TouchableOpacity onPress={this.props.t}>
                            <TakeerText style={{color:Colors.primaryAccent}}>Log In</TakeerText>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        );
    }
}

const mapStateToProps = (state) =>({
    settings: state.settings
});

export default connect(mapStateToProps,actions)(SignupComponent);