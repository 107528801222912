import React, { Component, useState } from "react";
import {
  View,
  Text,
  ScrollView,
  Image,
  TouchableOpacity,
  SafeAreaView,
  Dimensions,
  StyleSheet
} from "react-native";
import MapView, {Marker} from 'react-native-maps';
import { TabView, TabBar, SceneMap } from 'react-native-tab-view';
import * as actions from "../../actions";
import { connect } from "react-redux";
import { Query } from "redux-saga-requests-react";
import { LinearGradient } from "expo-linear-gradient";
import CardList from "react-native-card-animated-modal";
import { Colors, Styles, Images, Types, Translation } from "../../Common";
import TakeerText from "../../components/TakeerText";
import TakeerIcon from "../../components/TakeerIcon";

import Header from "./Header";
 
const now = new Date();
const initialLayout = { width: Dimensions.get('window').width };
const GOOGLE_API_KEY = 'AIzaSyAn9bCm2uH3rwMZt_Q7xZEMBxW4JvpS4dY';

const { width, height } = Dimensions.get('window');
const ASPECT_RATIO = width / height;
const LATITUDE = 37.771707;
const LONGITUDE = -122.4053769;
const LATITUDE_DELTA = 0.0922;
const LONGITUDE_DELTA = LATITUDE_DELTA * ASPECT_RATIO;

const AppleCard = ({item,index})  => (
      <View key={`${item.key}-key`}>
        <View
          style={{
            width: "100%",
            padding: 16,
            backgroundColor: "#e6c8b6",
          }}
        >
          <Text
            style={{
              fontSize: 12,
              marginBottom: 5,
              fontWeight: "bold",
              color: "rgba(0, 0, 0, 0.5)"
            }}
          >
            {item.locationName}
          </Text>
          <View
          style={{          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between"
        }}>
          <Text
            style={{
              fontSize: 26,
              maxWidth: "60%",
              fontWeight: "bold",
              color: "rgb(51, 51, 51)"
            }}
          >
            {item.offerDescription}
          </Text>
          <View>
          <TouchableOpacity
            style={{
              backgroundColor: "#44403d",
              padding: 7,
              minWidth: 60,
              borderRadius: 16,
              right:0,
              marginBottom:10
            }}
            onPress={() => {
              console.log("IG Card");
            }}
          >
            <Text style={{ color: "rgb(255, 255, 255)", textAlign: "center" }}>
            <TakeerIcon
                                  iconType="Ionicons"
                                  iconName="md-navigate"
                                  iconSize={20}
                                  iconColor="#ef7e20"
                                  iconPosition="center"
                                />{" "}
                                215m</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              backgroundColor: "#44403d",
              padding: 7,
              minWidth: 60,
              borderRadius: 16,
              right:0,
            }}
            onPress={() => {
              console.log("IG Card");
            }}
          >
            <Text style={{ color: "rgb(255, 255, 255)", textAlign: "center" }}>
            <TakeerIcon
                                  iconType="Ionicons"
                                  iconName="ios-alarm"
                                  iconSize={20}
                                  iconColor="#ef7e20"
                                  iconPosition="center"
                                />{" "}
                                60:00</Text>
          </TouchableOpacity>
        </View>
</View>
        </View>
      </View>
);

const LoadingComponent = ({ label }) => (
  <SafeAreaView>
  <TakeerText>
    {Translation.get('loading_data')}
    {label}
  </TakeerText>
  </SafeAreaView>
);

const ErrorComponent = ({ error, label, queryFor }) => {
  //if(queryFor = "offer"){console.log("ErrorComponent for Offer - Will try again"); this.process.fetchAllOffers}
  //if(queryFor = "location"){console.log("ErrorComponent for location - Will try again"); this.process.fetchAllLocations}
  return (
    <SafeAreaView>
    <TakeerText>
    {Translation.get('loading_data_failed')} {error.status}
      {label}
    </TakeerText>
    </SafeAreaView>
  );
};

class TabHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      routes:[
        { key: 'om', title: Translation.get('offer_details_tab_about') },
        { key: 'kort', title: Translation.get('offer_details_tab_map') },
      ],
      tabIndex:0,
      offerRetry:false,
      locationRetry:false,
    };
  }



  setIndex = (tabIndex) => {
    this.setState({tabIndex})
  }
  async componentDidMount() {
    console.log("Requested offers");
    await this.props.fetchAllOffers();
    console.log("Requested locations");
    await this.props.fetchAllLocations();
  }

  goLocation = location => {
    this.props.navigation.navigate("Location");
  };

  renderSubtitle(subtitle, count) {
    const ending = subtitle.length > count ? "..." : "";
    return (
      <View>
        <TakeerText
          style={{
            color: "#FFF",
            opacity: 0.5
          }}
        >
          {subtitle.slice(0, count) + ending}
        </TakeerText>
      </View>
    );
  }

  render() {
    /*const ErrorComponent = ({ error, label, queryFor }) => {
      if(queryFor = "offer" && !this.state.offerRetry){console.log("ErrorComponent for Offer - Will try again"); this.setState({ offerRetry:true});this.props.fetchAllOffers()}
      if(queryFor = "location"){console.log("ErrorComponent for location - Will try again"); this.setState({ locationRetry:true}); this.props.fetchAllLocations()}
      return (
        <SafeAreaView>
        <TakeerText>
        {Translation.get('loading_data_failed')} {error.status}
          {label}
        </TakeerText>
        </SafeAreaView>
      );
    };*/
    return (
      <View style={{ flex: 1, backgroundColor: Colors.secondary }}>
        <Query
          type={Types.OFFER.FETCH_ALL}
          // or selector={myQuerySelector}
          isDataEmpty={query =>
            Array.isArray(query.data) ? query.data.length === 0 : !query.data
          }
          showLoaderDuringRefetch={false}
          noDataMessage={<Text>There is no data yet</Text>}
          errorComponent={this.ErrorComponent}
          errorComponentProps={{ label: "Error label", "queryFor":"offer" }}
          loadingComponent={LoadingComponent}
          loadingComponentProps={{ label: "Loading label" }}
        >
          {data => {
            let CARDS = []
            data.data.data.map((v, i) => {
              CARDS.push({
                image:Images.business,
                renderItem:AppleCard,
                key:v.id,
                locationName:v.location.name,
                locationDescription:v.location.description,
                offerDescription:v.offer_description, 
                data:v,
                
              })
            });
            return (
              <CardList
              
                detailsContainerStyle={{borderRadius: 0,borderBottomRightRadius:15,borderBottomLeftRadius:15, backgroundColor: "transparent", borderTopWidth:1 }}
                cardContainerStyle={{ backgroundColor: "transparent" }}
                
                safeAreaStyle={{backgroundColor:'transparent'}}
                listContainerStyle={{
                  flexGrow: 1,
                  backgroundColor: Colors.secondary,
                  
                }}
                listProps={{
                  ListHeaderComponent: () => (
                    <Query
                      type={Types.LOCATION.FETCH_ALL}
                      // or selector={myQuerySelector}
                      isDataEmpty={query =>
                        Array.isArray(query.data)
                          ? query.data.length === 0
                          : !query.data
                      }
                      showLoaderDuringRefetch={false}
                      noDataMessage={<Text>There is no data yet</Text>}
                      errorComponent={this.ErrorComponent}
                      errorComponentProps={{ label: "Error label","queryFor":"location" }}
                      loadingComponent={LoadingComponent}
                      loadingComponentProps={{ label: "Loading label" }}
                    >
                      {data => (
                        <ScrollView style={{paddingHorizontal:16}} horizontal={true} indicatorStyle="white">
                          {data.data.data.map((v, i) => (
                            <LinearGradient
                              key={`${i}-key`}
                              colors={["#e6c8b6", "#ef7e20"]}
                              //end={[1,0]}
                              style={[
                                {
                                  backgroundColor: "#ef7e20",
                                  borderRadius: 15,
                                  marginRight: 5
                                },
                                {
                                  shadowColor: "#000",
                                  shadowOffset: {
                                    width: 0,
                                    height: 6
                                  },
                                  shadowOpacity: 0.37,
                                  shadowRadius: 7.49
                                }
                              ]}
                            >
                              <TouchableOpacity
                                style={[Styles.featuredHolder]}
                                key={`${i}-key`}
                                onPress={() => this.goLocation(v)}
                              >
                                <View style={Styles.featuredHolderImg}>
                                  <Image
                                    source={Images.business}
                                    style={Styles.featuredImage}
                                    resizeMethod="scale"
                                  />
                                </View>
                                <View style={Styles.featuredHolderTitleHolder}>
                                  <TakeerText
                                    style={Styles.featuredHolderTitle}
                                  >
                                    {v.name}
                                  </TakeerText>
                                  {/*this.renderSubtitle(v.description, 30)*/}
                                </View>
                              </TouchableOpacity>
                            </LinearGradient>
                          ))}
                        </ScrollView>
                      )}
                    </Query>
                  )
                }}
                data={CARDS}
                renderItem={({ item, index }) => {
                  /* Render card per item */
                  if (item.renderItem) return item.renderItem({ item, index });
                  if (item.component) return item.component;

                  /* Default card when not specified */
                  return (
                    <View>
                      <Text>Default Content</Text>
                    </View>
                  );
                }}
                renderDetails={({ item, index2 }) => {
  //const [setIndex] = useState(0);

  const FirstRoute = () => (
    <View
                    style={{
                      paddingVertical: 0,
                      paddingHorizontal: 0,
                      backgroundColor: "#e6c8b6"
                    }}
                  >
                  <View style={{display:"none",flex:1,flexDirection:'row',alignItems:'center',textAlign:'center',borderBottomWidth:1,justifyContent:"space-evenly"}}>
                    <View style={{flex:1,borderRightWidth:1, alignItems:'center',textAlign:'center'}}><Text>                                <TakeerIcon
                                  iconType="Ionicons"
                                  iconName="md-navigate"
                                  iconSize={20}
                                  iconColor="#ef7e20"
                                  iconPosition="center"
                                />{" "}
                                215m</Text></View>
                    <View style={{flex:1,alignItems:'center',textAlign:'center'}}><Text style={{flex:1,alignItems:'center',textAlign:'center'}}>                                <TakeerIcon
                                  iconType="Ionicons"
                                  iconName="ios-alarm"
                                  iconSize={20}
                                  iconColor="#ef7e20"
                                  iconPosition="center"
                                  style={{flex:1,alignItems:'center',textAlign:'center'}}
                                />{" "}
                                60:00</Text></View>
                  </View>
                            <View style={{ 
                                           
              borderTopWidth:1}}><Text
            style={{
              fontSize: 20,
              maxWidth: "60%",
              fontWeight: "bold",
              color: "rgb(51, 51, 51)", 
              paddingHorizontal:16,
            }}
          >
            {Translation.get('offer_details_about_header')}
          </Text>
                    <Text style={{ color: "rgba(0, 0, 0, 0.7)", fontSize: 18,paddingHorizontal:16 }}>
                      {item.locationDescription}
                    </Text>
                    </View>
                  </View>
  );

const SecondRoute = () => {

  //this.scroller.scrollTo({x: 0, y: 0, animated: true})
  return(
  <View style={[styles.scene]}>
  <MapView style={styles.mapStyle}
  showsUserLocation={true}
            initialRegion={{
            "latitude": Number(item.data.location.latitude),
            "latitudeDelta": LATITUDE_DELTA,
            "longitude": Number(item.data.location.longitude),
            "longitudeDelta": LONGITUDE_DELTA,
          }}><Marker
      coordinate={{
          latitude: Number(item.data.location.latitude),
          longitude: Number(item.data.location.longitude)
        }}
      title={item.data.location.name}
    /></MapView></View>
)};
  const renderScene = SceneMap({
    om: FirstRoute,
    kort: SecondRoute,
  });
  const renderTabBar = props => (
  <TabBar
    {...props}
    indicatorStyle={{ backgroundColor: 'white',height:0 }}
    style={{   }}
    activeColor="black"
      inactiveColor="#44403d"
      tabStyle={{backgroundColor:"#dbb069"}}
  />
);

  return (  
    <TabView
      navigationState={{ index:this.state.tabIndex, routes:this.state.routes }}
      renderScene={renderScene}
      onIndexChange={this.setIndex}
      initialLayout={initialLayout}
      renderTabBar={renderTabBar}
      lazy={true}
    />
  );
}
}
              />
            );
          }}
        </Query>
        <View
          style={{
            flex: 1,
            backgroundColor: Colors.secondary,
            display: "none"
          }}
        >
          <Header navigation={this.props.navigation} />
          <ScrollView style={Styles.containerAfterHeader}>
            <View
              style={{
                flexDirection: "row"
              }}
            >
              <View style={{ width: "50%" }}>
                <TakeerText style={Styles.headerTitle}>{Translation.get('new_locations_header')}</TakeerText>
              </View>
              <View
                style={{
                  width: "50%",
                  alignItems: "flex-end",
                  justifyContent: "center"
                }}
              >
                {/* This part can add a "See all"}
                                <TouchableOpacity style={{marginTop:10}}>
                                    <TakeerText style={{
                                               color:Colors.primaryAccent,
                                        fontSize:16
                                    }}>See all</TakeerText>
                                </TouchableOpacity>
                                */}
              </View>
            </View>

            <View>
              <Query
                type={Types.LOCATION.FETCH_ALL}
                // or selector={myQuerySelector}
                isDataEmpty={query =>
                  Array.isArray(query.data)
                    ? query.data.length === 0
                    : !query.data
                }
                showLoaderDuringRefetch={false}
                noDataMessage={<Text>There is no data yet</Text>}
                errorComponent={ErrorComponent}
                errorComponentProps={{ label: "Error label","queryFor":"location" }}
                loadingComponent={LoadingComponent}
                loadingComponentProps={{ label: "Loading label" }}
              >
                {data => (
                  <ScrollView horizontal={true} indicatorStyle="white">
                    {data.data.data.map((v, i) => (
                      <LinearGradient
                        key={`${i}-key`}
                        colors={["#e6c8b6", "#ef7e20"]}
                        //end={[1,0]}
                        style={[
                          {
                            backgroundColor: "#ef7e20",
                            borderRadius: 15,
                            marginRight: 5
                          },
                          {
                            shadowColor: "#000",
                            shadowOffset: {
                              width: 0,
                              height: 6
                            },
                            shadowOpacity: 0.37,
                            shadowRadius: 7.49
                          }
                        ]}
                      >
                        <TouchableOpacity
                          style={[Styles.featuredHolder]}
                          key={`${i}-key`}
                          onPress={() => this.goLocation(v)}
                        >
                          <View style={Styles.featuredHolderImg}>
                            <Image
                              source={Images.business}
                              style={Styles.featuredImage}
                              resizeMethod="scale"
                            />
                          </View>
                          <View style={Styles.featuredHolderTitleHolder}>
                            <TakeerText style={Styles.featuredHolderTitle}>
                              {v.name}
                            </TakeerText>
                            {this.renderSubtitle(v.description, 30)}
                          </View>
                        </TouchableOpacity>
                      </LinearGradient>
                    ))}
                  </ScrollView>
                )}
              </Query>
            </View>
            <View>
              <TakeerText style={Styles.headerTitle}>{Translation.get('latest_offers_header')}</TakeerText>
            </View>
            <Query
              type={Types.OFFER.FETCH_ALL}
              // or selector={myQuerySelector}
              isDataEmpty={query =>
                Array.isArray(query.data)
                  ? query.data.length === 0
                  : !query.data
              }
              showLoaderDuringRefetch={false}
              noDataMessage={<Text>There is no data yet</Text>}
              errorComponent={ErrorComponent}
              errorComponentProps={{ label: "Error label","queryFor":"offer" }}
              loadingComponent={LoadingComponent}
              loadingComponentProps={{ label: "Loading label" }}
            >
              {data => (
                <View>
                  {data.data.data.map((v, i) => (
                    <LinearGradient
                      key={`${i}-key`}
                      colors={["#e6c8b6", "#ef7e20"]}
                      start={[1, 0]}
                      style={[
                        {
                          backgroundColor: "#ef7e20",
                          borderRadius: 15,
                          marginBottom: 10
                        },
                        {
                          shadowColor: "#000",
                          shadowOffset: {
                            width: 0,
                            height: 6
                          },
                          shadowOpacity: 0.37,
                          shadowRadius: 7.49
                        }
                      ]}
                    >
                      <TouchableOpacity
                        key={`${i}-latest`}
                        style={Styles.latestHolder}
                        onPress={() => this.goLocation(v)}
                      >
                        <View style={Styles.latestImage}>
                          <Image
                            source={Images.business}
                            style={Styles.latestCover}
                            resizeMethod="scale"
                          />
                        </View>
                        <View style={[Styles.latestContentHolder, { flex: 1 }]}>
                          <View>
                            <TakeerText style={Styles.latestTitle}>
                              {v.location.name}
                            </TakeerText>
                            <View style={{ alignItems: "center" }}>
                              {this.renderSubtitle(v.offer_description, 200)}
                            </View>
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                              paddingHorizontal: 8
                            }}
                          >
                            <View
                              style={{
                                backgroundColor: "#28606b",
                                borderRadius: 15,
                                width: 70
                              }}
                            >
                              <TakeerText style={Styles.latestListH}>
                                <TakeerIcon
                                  iconType="Ionicons"
                                  iconName="md-navigate"
                                  iconSize={20}
                                  iconColor="#ef7e20"
                                  iconPosition=""
                                />{" "}
                                215m
                              </TakeerText>
                            </View>
                            <View
                              style={{
                                backgroundColor: "#28606b",
                                borderRadius: 15,
                                width: 80
                              }}
                            >
                              <TakeerText style={Styles.latestListH}>
                                <TakeerIcon
                                  iconType="Ionicons"
                                  iconName="ios-alarm"
                                  iconSize={20}
                                  iconColor="#ef7e20"
                                  iconPosition=""
                                />{" "}
                                {v.minutes}:00
                              </TakeerText>
                            </View>
                          </View>
                        </View>
                      </TouchableOpacity>
                    </LinearGradient>
                  ))}
                </View>
              )}
            </Query>
          </ScrollView>
        </View>
      </View>
    );
  }
}
const styles = StyleSheet.create({
  scene: {
    
  },
  mapStyle: {
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height/2,
  },
});
const mapStateToProps = state => ({
  settings: state.settings,
  requests: state.requests
});
export default connect(mapStateToProps, actions)(TabHome);
